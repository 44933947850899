<script setup>
import IconAnnouncement from "@/components/icons/announcement";
import IconPratilipi from "@/components/icons/pratilipi";
import moment from "moment"
import {ref, onMounted, computed} from "vue";

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const messageRef = ref();
const canExpand = ref(false);

const lineCropStyle = ref({
  "-webkit-line-clamp": 3,
  "line-clamp": 3,
})

const isExpanded = ref(false);

const isImg = computed(() => isValidUrl(props.item.message))

const onReadMoreClick = () => {
  if (!isExpanded.value) {
    removeLineCropStyle();
  } else {
    applyLineCropStyle();
  }
  isExpanded.value = !isExpanded.value;
}

const applyLineCropStyle = () => {
  lineCropStyle.value = {
    "-webkit-line-clamp": 3,
    "line-clamp": 3,
  }
}

const removeLineCropStyle = () => {
  lineCropStyle.value = {}
}

const isValidUrl = (text) => {
  let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(text);
}

onMounted(() => {
  const e = messageRef.value;
  if (!isImg.value) {
    canExpand.value = (e.offsetHeight < e.scrollHeight)
  }
})

</script>

<template>
  <div>
    <div class="spacer-1-5"></div>
    <div class="notice-item">
      <div class="notice-header">
        <IconAnnouncement v-if="item.type == 'ANNOUNCEMENT'"/>
        <IconPratilipi v-if="item.type == 'GENERAL'"/>
        <h3>{{ item.title }}</h3>
      </div>
      <div class="spacer-0-5"></div>
      <p class="notice-date">{{ moment(item.created_at).format("DD/MM/YY (hh:mm A)") }}</p>
      <div class="spacer-0-5"></div>
      <p ref="messageRef" :style="lineCropStyle" :id="item.id" class="notice-text" v-if="!isImg">{{ item.message }}</p>
      <img class="notice-img" :src="item.message" v-if="isImg"/>
      <div class="spacer-0-5"></div>
      <div v-if="canExpand"  class="read-more-container">
        <v-btn @click="onReadMoreClick" text x-small color="secondary">
          {{ isExpanded ? "Read less" : "Read more" }}
        </v-btn>
      </div>
    </div>
    <div class="spacer-1-5"></div>
  </div>
</template>

<style scoped>
h2,
h3 {
  color: var(--color-text)
}

.notice-item {
  padding: 0.8rem;
  border-radius: 0.5rem;
  border: 1px solid var(--color-border);
}

.notice-header {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.notice-date {
  color: var(--color-border-secondary);
  font-size: smaller;
}

.notice-text {
  color: var(--color-text-secondary);
  font-size: medium;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.notice-img {
  width: 100%;
  border-radius: 0.5rem;
  object-fit: contain;
}

.read-more-container {
  display: flex;
  justify-content: end;
}

.read-more {
  color: var(--color-accent);
  font-size: small;
  font-weight: bold;
}

</style>
