<script setup>
import "@/assets/base.css";
import "@/assets/utility.css";
import {ref, onMounted, onUnmounted} from "vue"
import NoticeItem from "@/components/notice/NoticeItem";
import { apiComic } from "@/http/DataAccessor.js";
import * as _ from "lodash";


const isLoading = ref(false);

const total = ref(0);
const nextSegment = ref("");
const pagedData = ref([]);

const noticeContainer = ref();

const delay = (millis) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve()
    }, millis)
  })
}


const loadNextPage = async () => {
  if (isLoading.value) return;
  if (nextSegment.value === "skip") return;

  let query = "";
  if (nextSegment.value) {
    query += "?" + nextSegment.value;
  }

  await withLoading()
      .then(() => apiComic.getNotices(query))
      .then((data) => {
        total.value = data.total;
        nextSegment.value = data.nextSegment ? data.nextSegment : "skip";

        pagedData.value = _.concat(pagedData.value, data.data);
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => stopLoading());
}

const withLoading = async () => {
  isLoading.value = true
}

const stopLoading = async () => {
  isLoading.value = false
}

const onScroll = (event) => {
  if (Math.ceil(window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500)) {
    loadNextPage(); // fire & forget
  }
}

onMounted(async () => {
  window.addEventListener("scroll", onScroll);
  await loadNextPage();
})
onUnmounted(async () => {
  window.removeEventListener("scroll", onScroll);
})
</script>

<template>
  <v-app>
    <v-main class="main-class">
      <v-progress-linear
          class="progress"
          :active="isLoading"
          indeterminate
          height="3"
          color="secondary"
      ></v-progress-linear>
      <section>

        <div class="spacer-1-5"></div>

        <div class="toolbar">
          <div class="bar">|</div>
          <h2>Notices</h2>
        </div>

        <div ref="noticeContainer" class="notice-container" v-on:scroll="onScroll">
          <div v-for="item in pagedData" :key="item.id">
            <NoticeItem :item="item" />
          </div>
        </div>
      </section>
    </v-main>
  </v-app>
</template>

<style scoped>
h2,
h3 {
  color: var(--color-text)
}

.progress {
  position: fixed;
}

.toolbar {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.bar {
  height: 100%;
  background-color: var(--color-text);
  color: var(--color-text);
}
</style>
